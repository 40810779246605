* {
  margin: 0;

  box-sizing: border-box;
}

body {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  min-width: 100vw;
  padding: 1rem;
  background-color: rgb(14, 14, 14); 
  }