.card {
    background-color: #ffffff21;
    color: white;
    border-radius: 1rem;
    padding: 1rem;
   
}

.profile {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 1rem;
}

.avatar {
    max-width: 100px;
    border-radius: 50%;
    margin-bottom: 0.5rem;
}

.button-wrap { 
    margin-top: 0.6rem;
}

.button {
    display: flex;
    align-items: center;
    padding: 0.8rem 0.8rem;
    text-decoration: none;
    color: white;
    width: 100%;
    border-radius: 0.4rem;
    border: 1px solid #BF9002;
    font-size: 1rem;
    transition: 1s cubic-bezier(0.075, 0.82, 0.165, 1);
    gap: 0.5rem;
}

.button:hover {
    background-color: #BF9002;
    border: 1px solid white;
}